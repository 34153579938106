import React, { useState } from "react";
import { Box, Button, CircularProgress, Avatar } from "@mui/material";
import useAuth from "hooks/useAuth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ImageUploader = ({ initialImage, apiUrl, routeDefault, prevRoute = null, callback = () => {} }) => {
  const [image, setImage] = useState(
    `${process.env.REACT_APP_API_URL}${initialImage}`
  );
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { axios } = useAuth();
  let navigate = useNavigate();

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  
  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append(`${routeDefault}[img]`, file);

    setLoading(true);

    try {
      const response = await axios.put(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Assuming the response contains the updated image URL
      setImage(`${process.env.REACT_APP_API_URL}${response.data.img_url}`);
      toast.success("Atualizado com sucesso!");
      setFile(null)
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap={2}
      sx={{ mb: 2 }}
    >
      <Avatar
        src={image}
        alt="Uploaded Image"
        sx={{ width: 120, height: 120 }}
      />
      <input
        accept="image/*"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="image-upload"
      />
      <label htmlFor="image-upload">
        <Button variant="outlined" component="span">
          Trocar imagem
        </Button>
      </label>
      {file && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Salvar imagem"}
        </Button>
      )}
    </Box>
  );
};

export default ImageUploader;
